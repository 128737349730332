import htmlParser from 'html-react-parser';
import ResizableImage, { IResizableImage } from 'src/components/moray/ResizeableImage';
import './HighlightBanner.scss';
import MoraySpinnerSmall from './MoraySpinnerSmall';

export interface IHighlightBanner {
  titleText: string | undefined;
  descriptionText?: string;
  textContainerWidth?: '5' | '6' | '7' | '8' | '9' | '10';
  buttonText?: string;
  buttonOnClick?(): any;
  buttonAriaLabel?: string;
  resizableImageProps: IResizableImage;
  reverseTextAlign?: boolean;
  leftTextAlign?: boolean;
  reverseTextColor?: boolean;
  isLoading?: boolean;
  gradient?: 'left' | 'right';
  backgroundClassName?: string;
  containerClassName?: string;
  cardContainerClassNames?: string;
}

const HighlightBanner = ({
  titleText,
  descriptionText,
  textContainerWidth,
  buttonText,
  buttonOnClick,
  buttonAriaLabel,
  resizableImageProps,
  reverseTextAlign,
  leftTextAlign,
  reverseTextColor,
  isLoading,
  gradient,
  backgroundClassName,
  containerClassName,
  cardContainerClassNames
}: IHighlightBanner) => (
  <div className="card-img-overlay highlight-banner-container">
    <div
      className={`card-background my-0 ${gradient !== undefined ? `${gradient}-gradient` : ''} ${
        backgroundClassName ?? ''
      }`}
    >
      <ResizableImage {...resizableImageProps} />
    </div>
    <div className="card-foreground ">
      <div className={`container ${containerClassName ?? ''}`}>
        <div className={`d-flex mt-md-n5 my-lg-5 ${reverseTextAlign ? 'flex-row-reverse' : ''}`}>
          <div className={`w-100 w-lg-col-${textContainerWidth ?? '5'} text-container-overflow`}>
            <div
              className={`card material-md-card py-5 ${leftTextAlign ? 'left-text-align' : 'px-md-5'} ${
                cardContainerClassNames ?? ''
              }`}
            >
              <div className="card-body ">
                {descriptionText ? (
                  <h2
                    className={`h2 ${
                      reverseTextColor ? 'card-body-text-black' : 'card-body-text'
                    } font-weight-semibold`}
                  >
                    {titleText}
                  </h2>
                ) : (
                  <h4
                    className={`h4 ${
                      reverseTextColor ? 'card-body-text-black' : 'card-body-text'
                    } font-weight-semibold`}
                  >
                    {titleText}
                  </h4>
                )}
                <div className="mb-4">
                  <p />
                  <p className={`banner-desc ${reverseTextColor ? 'card-body-text-black' : 'card-body-text'}`}>
                    {htmlParser(descriptionText ?? '')}
                  </p>
                </div>
                {buttonText !== undefined && (
                  <div className="link-group">
                    {isLoading ? (
                      <MoraySpinnerSmall />
                    ) : (
                      <button className="btn btn-primary" aria-label={buttonAriaLabel} onClick={buttonOnClick}>
                        {buttonText}
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default HighlightBanner;
