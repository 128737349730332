import { IResizableImage } from "src/components/moray/ResizeableImage";
import localeService from "src/dataServices/LocaleService";
import { scrollOptions } from "src/common/utilities/Utilities";
import HighlightBanner, { IHighlightBanner } from '../shared/HighlightBanner';
import HighlightBannerCarousel from "../shared/HighlightBannerCarousel";
import useD365CopilotOfferUpdate from './useD365CopilotOfferUpdate';

interface ID365TopBanner {
  offersElement: HTMLDivElement | null;
}

const D365TopBanner = ({ offersElement }: ID365TopBanner) => {
  const { isD365CopilotOfferAvailable } = useD365CopilotOfferUpdate();

  const resizableImageProps: IResizableImage[] = [
    {
      root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/D365`,
      imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/D365/D365-HighlightBanner1-540.jpg`,
      imageClassName: 'img-fill-container card-img',
      imageAlt: localeService.getText('Dynamic365V2', 'HighlightBannerTitle1'),
      fileSize1920: 'D365-HighlightBanner1-1920.jpg',
      fileSize1400: 'D365-HighlightBanner1-1400.jpg',
      fileSize1084: 'D365-HighlightBanner1-1084.jpg',
      fileSize860: 'D365-HighlightBanner1-860.jpg',
      fileSize540: 'D365-HighlightBanner1-540.jpg',
      fileSize360: 'D365-HighlightBanner1-360.jpg'
    },
    {
      root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/D365`,
      imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/D365/D365-HighlightBanner2-540.jpg`,
      imageClassName: 'img-fill-container card-img',
      imageAlt: localeService.getText('Dynamic365V2', 'HighlightBannerTitle2'),
      fileSize1920: 'D365-HighlightBanner2-1920.jpg',
      fileSize1400: 'D365-HighlightBanner2-1400.jpg',
      fileSize1084: 'D365-HighlightBanner2-1084.jpg',
      fileSize860: 'D365-HighlightBanner2-860.jpg',
      fileSize540: 'D365-HighlightBanner2-540.jpg',
      fileSize360: 'D365-HighlightBanner2-360.jpg'
    },
    {
      root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/D365`,
      imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/D365/D365-HighlightBanner3.jpg`,
      imageClassName: 'card-img w-100',
      imageAlt: localeService.getText('Dynamic365V2', 'HighlightBannerImgAlt3')
    }
  ];

  const children: React.ReactElement<IHighlightBanner>[] = [
    <HighlightBanner
      titleText={localeService.getText('Dynamic365V2', 'HighlightBannerTitle3')}
      descriptionText={localeService.getText('Dynamic365V2', 'HighlightBannerDescription3')}
      buttonText={localeService.getText('Dynamic365V2', 'HighlightBannerButton3')}
      buttonOnClick={() => {
        window.open(localeService.getText('Dynamic365V2', 'HighlightBannerButton3Link'), '_blank');
      }}
      buttonAriaLabel={localeService.getText('Dynamic365V2', 'HighlightBannerButton3AriaLabel')}
      resizableImageProps={resizableImageProps[2]}
    />,
    <HighlightBanner
      titleText={localeService.getText('Dynamic365V2', 'HighlightBannerTitle1')}
      descriptionText={localeService.getText('Dynamic365V2', 'HighlightBannerDescription1')}
      buttonText={localeService.getText('Dynamic365V2', 'HighlightBannerButton1')}
      buttonOnClick={() => {
        if (offersElement !== null) {
          offersElement.scrollIntoView(scrollOptions);
        }
      }}
      buttonAriaLabel={localeService.getText('Dynamic365V2', 'HighlightBannerButton1AriaLabel')}
      resizableImageProps={resizableImageProps[0]}
    />
  ];

  if (!isD365CopilotOfferAvailable) {
    children.shift(); // Remove the first child if offer is not available
  }

  return <HighlightBannerCarousel>{children}</HighlightBannerCarousel>;
};

export default D365TopBanner;
